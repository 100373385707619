Print = {
    initialize: function(parent, controller, actions) {
        Print._controller = controller;
        Print._actions = actions.filter(i => i.category == 'print');

        Print._button = document.createElement('button');
        Print._button.id = 'print';
        Print._button.innerHTML = 'p';
        Print._button.disabled = !Print._actions.length;
        Print._button.addEventListener('click', () => { Print.show() });
        parent.appendChild(Print._button);
    },

    show: function() {
		let sheets = Print._actions.filter(i => !i.paper || i.paper == 'sheet');
		let receipts = Print._actions.filter(i => i.paper == 'receipt');
		let items = [];


		if (sheets.length) {
			sheets.forEach(sheet => {
				items.push({
					icon:		sheet.icon ? sheet.icon : 'print',
					title:		sheet.title,
					enabled:	(typeof sheet.enabled === 'undefined' || sheet.enabled) && ! System.OS.Android,
					onSelect:	function(item) { Print.handle(item) }.bind(this, sheet)
				});
			})

			items.push({
				type:	'separator'
			});
		}

		if (receipts.length) {
			receipts.forEach(receipt => {
				items.push({
					icon:		receipt.icon ? receipt.icon : 'print',
					title:		receipt.title,
					enabled:	(typeof receipt.enabled === 'undefined' || receipt.enabled) && Print._controller.devices.options.settings.receiptPrinter.method,
					onSelect:	function(item) { Print.handle(item) }.bind(this, receipt)
				});
			})

			items.push({
				type:	'separator'
			});
		}

		let offset = Print._button.viewportOffset();

        setTimeout(() => {
			Print._button.dataset.state = 'active';

			new Widgets.ContextMenu({
				x:						offset.left + 15,
				y:						offset.top + 30,
				align:					'right',
				verticalAlign:			'top',
				overlay:				true,
				items:					items,
				onClose:				() => delete Print._button.dataset.state
			});
		}, 0);
    },

    handle: async function(item) {
		item = Object.assign({
			name:		'rapportage.html',
		}, item);

		if (item.type == 'action') {
			let result = await Runtime.Loader.Platform.command('callback', {
				callback:	item.action
			});

			if (!result) {
				return;
			}

			item = result;
		}

		let status = new Window.Status('Afdrukken...');
            
		Print._controller.devices.printSheet(item, () => {
			setTimeout(() => {
				status.close();
			}, 1000);
		});
	},

    update: function(actions) {
        Print._actions = actions.filter(i => i.category == 'print');

		if (Export._button) {
            Print._button.disabled = !Print._actions.length;
        }
    }
}