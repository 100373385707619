CSS.insert(`

    .helpWindow .frame {
        padding: 0;
    }
   
    .helpContainer {
    }

    .helpContainer .widget.panel {
        margin: 0;
    }

    .helpContainer iframe {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .helpWindow .help {
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 5px;
        position: relative;
        font-size: 0.8em;
    }

    .helpWindow:has(.bot) .help {
        background: #f6f6f6;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .helpWindow .help main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-left: 24px;
        margin-right: 6px;
    }

    .helpWindow .help footer {
        padding: 10px 28px 0px;
        color: #999;
        text-align: center;
        font-size: 0.7em;
        border-top: 1px solid #e6e6e6;
        margin-top: 4px;
    }

    .helpWindow .help div {
        display: flex;
        align-items: center;
    }

    .helpWindow .help div img {
        width: 32px;
        margin: 6px 12px 6px 0px;
    }

    .helpWindow .help a {
        text-decoration: none;
    }
    .helpWindow .help a::after {
        font-family: SalonIcon, SalonWidgets; font-size: 12px; font-weight: normal; font-style: normal; text-transform: none; padding: 1px; -webkit-font-smoothing: antialiased;
        content: '→';
        color: #888;
        margin-left: 2px;
    }

    .helpWindow .help a,
    .helpWindow .help a:visited {
        color: #444;
    }

    .helpWindow #aboutButton {
        position: absolute;
        bottom: 0px;
        left: calc(50% - 20px);
        padding: 6px;
    }

`);


Help = {
    
    show: async function(pointer) {

        /* Fetch the remote config file, which defines what we need to show */

        let config = {
            chatbot: { enabled: false },
            remote: { enabled: false }
        }
        
        let base = 'https://salonhub.app/current';

        if (Settings.channel == 'beta') {
            base = 'https://beta.salonhub.nl/current';
        }
        else if (Settings.channel == 'dev') {
            base = 'https://salonhub.dev/current';
        }

        if (location.host == 'localhost:8888') {
            base = 'http://localhost:8888';
        }

        try {
            let request = await fetch(base + '/settings/help.json', { signal: AbortSignal.timeout(2000) });
            let modifications = await request.json();

            config = Object.assign(config, modifications);
        } catch (e) {
        }

        /* Create the window */

        let win = new Window({
            className: 	'helpWindow',
            pointer: 	{ target: pointer, side: 'left', size: 20, corner: 8, distance: 16, margin: 16 },
            width: 		400,
            height:		'auto',
        });
    
        let container = new Element('div');
        container.classList.add('helpContainer');
        win.appendChild(container);

        let appName = 'Salonhub';

        if (System.Runtime.Electron) {
            appName = System.OS.Mac ? 'Salonhub voor macOS' : 'Salonhub voor Windows';
        }
        else if (System.Runtime.Capacitor) {
            appName = 'Salonhub voor iPad';
        }
        else if (System.Features.TrustedWebActivity) {
            appName = System.OS.Chromebook ? 'Salonhub voor Chromebook' : 'Salonhub voor Android';
        }
        else {
            appName = Settings.channel == 'dev' ? 'Salonhub.dev' : 'Salonhub.app';
        }

        new Widgets.Panel(container, {
            style: 'none',
            template: `
                ${config.chatbot.enabled ? `
                    <div class="bot">
                        <iframe
                            src="${config.chatbot.url}"
                            width="100%"
                            style="height: 100%; min-height: ${config.chatbot.height}px"
                            frameborder="0"
                        ></iframe>
                    </div>
                ` : ''}

                <div class="help"${config.chatbot.enabled ? ` style="margin-top: ${config.chatbot.bottom}px"` : ''}>
                    <main>
                        <div>
                            <img src='../../../assets/icons/Color/icons/university.svg'>
                            <p>
                                <a href="https://salonhub.support" target="_blank">Kennisbank</a>
                            </p>
                        </div>
                        ${config.remote.enabled ? `
                            <div>
                                <img src='../../../assets/icons/Color/icons/rescue.svg'>
                                <p>                        
                                    <a href="${config.remote.url}" target="_blank">Hulp op afstand</a>
                                </p>
                            </div>
                        ` : ''}
                        <div>
                            <img src='../../../assets/icons/Color/icons/whatsapp.svg'>
                            <p>
                                <a href="https://wa.me/31621291544" target="_blank">06-21 29 1544</a>
                            </p>
                        </div>
                        <div>
                            <img src='../../../assets/icons/Color/icons/phone.svg'>
                            <p>                        
                                <a href="tel:+31508503710">(050) 850 3710</a>
                            </p>
                        </div>
                        <div>
                            <img src='../../../assets/icons/Color/icons/newsletter.svg'>
                            <p>                        
                                <a href="mailto:support@salonhub.nl">support@salonhub.nl</a>
                            </p>
                        </div>
                    </main>
                    <footer>
                        ${appName} ${Settings.channel != 'dev' ? Settings.version : ''}
                    </footer>
                </div>
            `
        });

        win.show();
    }
}