Widgets.Panel = Class.create(Widgets.Base, {
    name:		'panel',

    css: `
        body .widget.panel { position: relative; }
        body .widget.panel > div { position: relative; padding: 10px 10px 0 10px; margin: 0 0 30px 0; background: var(--panel-background); border-radius: 4px; border: 1px solid var(--panel-border); }
        body .widget.panel > div > .widget.panel > div { position: relative; margin: 10px 0; padding: 10px 10px 0 10px; border-radius: 4px; border: 1px solid #dedede; background: rgba(255,255,255,0.5); }

        body .widget.panel[data-style=page] > div { border-left: none !important; border-right: none !important; border-radius: 0 !important; margin-top: 16px; margin-bottom: 8px; margin-left: -20px; margin-right: -20px; padding: 10px 20px; }
        body .widget.panel[data-style=none] > div { background: none !important; padding: 0 !important; margin: 0 !important; border: none !important; }
        body .widget.panel[data-style=display] > div { box-shadow: var(--panel-border) 0px 1px 1px inset; padding-bottom: 8px; }
        body .widget.panel[data-style=bottom] > div { border-bottom: none; border-left: none; border-right: none; border-radius: 0px; border-bottom-left-radius: 4px; border-bottom-right-radius: 4px; margin: 0; overflow: hidden; }
        body .widget.panel[data-style=invalid] > div { color: #fff; border: 1px solid #ca5555; background: #ca5555; }
        body .widget.panel[data-style=blank] > div { border: 1px solid #ddd; background: #fff; padding: 20px 20px 10px;}
        body .widget.panel[data-style=scroll] > div { border: 1px solid #ddd; background: #fff; padding: 0 0 0 8px;}

        body .widget.panel[data-style=information] { display: flex; font-size: 0.8em; font-weight: normal; line-height: 150%; color: #555; border-left: 5px solid var(--panel-information-line); padding-left: 10px; margin: 20px 0 40px !important; }
        body .widget.panel[data-style=information] > div { background: none !important; padding: 0 !important; margin: 0 !important; border: none !important; }
        body .widget.panel[data-style=information]:before { content: '\\e91b'; font-family: SalonIcon, SalonWidgets; font-size: 18px; -webkit-font-smoothing: antialiased; color: var(--panel-information-icon); margin-right: 6px; } 
        body .widget.panel[data-style=information] a { color: var(--colors-blue); }
        body .widget.panel[data-style=information] p + p { margin-top: 0.8em; }

        body .widget.panel[data-style=warning] { display: flex; font-size: 0.8em; font-weight: normal; line-height: 150%; color: #555; border-left: 5px solid var(--panel-warning-line); padding-left: 10px; margin: 20px 0 40px !important; }
        body .widget.panel[data-style=warning] > div { background: none !important; padding: 0 !important; margin: 0 !important; border: none !important; }
        body .widget.panel[data-style=warning]:before { content: '\\0021'; font-family: SalonIcon, SalonWidgets; font-size: 18px; -webkit-font-smoothing: antialiased; color: var(--panel-warning-icon); margin-right: 6px; } 
        body .widget.panel[data-style=warning] a { color: var(--colors-blue); }
        body .widget.panel[data-style=warning] p + p { margin-top: 0.8em; }

        body .widget.panel[data-style=error] { display: flex; font-size: 0.8em; font-weight: normal; line-height: 150%; color: #555; border-left: 5px solid var(--panel-error-line); padding-left: 10px; margin: 20px 0 40px !important; }
        body .widget.panel[data-style=error] > div { background: none !important; padding: 0 !important; margin: 0 !important; border: none !important; }
        body .widget.panel[data-style=error]:before { content: '\\0021'; font-family: SalonIcon, SalonWidgets; font-size: 18px; -webkit-font-smoothing: antialiased; color: var(--panel-error-icon); margin-right: 6px; } 
        body .widget.panel[data-style=error] a { color: var(--colors-blue); }
        body .widget.panel[data-style=error] p + p { margin-top: 0.8em; }

        body .widget.panel[data-style=placeholder] > div {
            background: none !important; padding: 0 !important; margin: 0 !important; border: none !important;

            height: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 0.85em;
            line-height: 1.5;
            color: #000;
        }

        body .widget.panel[data-style=placeholder] > div > [data-icon]:before {
            font-size: 36px;
        }

        body .widget.panel[data-style=placeholder] > div > * {
            max-width: 500px;
            text-align: center;
            margin-bottom: 6px;
        }

        body .widget.panel[data-style=options] > div { 
            display: inline-block;
            margin: 0 0 0 110px !important;
            background: #f4f4f4 !important;
            border: none !important;
            padding: 20px 20px 8px !important;
        }

        body .widget.panel[data-style=columns] > div { margin-left: 110px !important; background: none !important;  margin-bottom: 12px !important; padding: 10px !important; }
        body .widget.panel[data-style=columns] > h3 { position: absolute; top: 4px; left: 0px; font-weight: normal; font-size: 0.8em; }

        body .widget.panel[data-style=filter] { margin: 0 !important; }
        body .widget.panel[data-style=filter] > div { background: none !important; padding: 0 10px !important; margin: 0 !important; border: none !important; border-right: 1px solid #ddd !important; height: 60px; line-height: 60px; }
        body .widget.panel[data-style=filter][data-size=small] > div { height: 30px; line-height: 30px; }

        body .widget.panel[data-style=insert] { margin: 14px -20px 24px !important; }
        body .widget.panel[data-style=insert] > div { border-left: none; border-right: none; border-radius: 0; padding: 16px 20px 2px; }

        body .column > .widget.panel > div { margin-right: 0; }
    `,

    initWidget: function() {
        if (this.options.height) this.setStyle({ 'height': this.options.height });
        if (this.options.width) this.setStyle({ 'width': this.options.width });

        if (this.options.style) this.dataset.style = this.options.style;
        if (this.options.size) this.dataset.size = this.options.size;

        if (this.options.title) {
            this.title = new Element('h3').update(this.options.title);
            this.insert(this.title);
        }

        this.container = new Element('div');
        this.insert(this.container);

        if (this.options.template) {
            if (typeof this.options.template == 'string') {
                this.container.innerHTML = this.options.template;
            }
            else if (typeof this.options.template == 'function') {
                if (this.options.data) {
                    render(this.container, this.options.template(this.options.data));
                }
            }
            else {
                render(this.container, this.options.template);
            }

            /* Capture link clicks in templates and use the application handler for them */
            this.container.addEventListener('click', (e) => {
                if (e.target.tagName == 'A') {
                    if (e.target.href.substring(0,4) == 'http') {
                        e.preventDefault();

                        if (Application.openInBrowser) {
                            Application.openInBrowser(e.target.href);
                        }

                        if (typeof Runtime !== 'undefined' && Runtime.Platform) {
                            Runtime.Platform.openInBrowser(e.target.href);
                        }
                    }
                }
            })
        }

        this.defineProperty('style', {
            get: function() { return this.options.style; },
            set: function(value) {
                    this.dataset.style = value;
                    this.options.style = value;
            }
        });

        this.defineProperty('data', {
            get: function() { return this.options.data; },
            set: function(data) {
                this.options.data = data;

                if (typeof this.options.template == 'function') {
                    render(this.container, this.options.template(data));
                }
            }
        });
    },

    clear: function() {
        this.container.update('');
    },

    update:	function(data) {
        this.container.innerHTML = data;
    },

    render: function(data) {
        if (typeof this.options.template == 'function') {
            render(this.container, this.options.template(data));
        }
    },

    appendChild: function(element) {
        this.container.appendChild(element);
    },

    querySelector: function(selector) {
        return this.container.querySelector(selector);
    }
});
