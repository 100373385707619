CSS.insert(`
	.window { position: fixed; top: 0; left: 0; background: #fff; color: #000; border-radius: 5px; z-index: 1001; transition: transform 0.1s ease-in-out; }
	.window:not(.hasOverlay) { box-shadow: #888 0px 0px 32px, #aaa 0px 0px 6px;  }
	.window:not(.primary).hasOverlay { box-shadow: rgba(34,34,34,0.4) 0px 0px 64px, rgba(34,34,34,0.1) 0px 0px 16px; }
	.window.isFullWidth { border-radius: 0; }
	body.Mobile .window { position: absolute; }
	.window .tip { position: absolute; width: 0; height: 0; border: 14px solid rgba(0,0,0,0); }
	.window[data-tip=top] .tip { border-bottom-color: #fff; }
	.window[data-tip=left] .tip { border-right-color: #fff; drop-shadow(4px 0px 2px rgba(0,0,0,0.3)); }
	.window[data-tip=right] .tip { border-left-color: #fff; drop-shadow(4px 0px 2px rgba(0,0,0,0.3)); }
	.window[data-tip=bottom] .tip { border-top-color: #fff; }
	.window .frame { padding: 20px; position: relative; height: calc(100% - 40px); }
	.window[data-scroll=yes] .frame { overflow-y: scroll; margin-right: -12px; padding-right: 26px; }
	.window[data-scroll=yes][data-tip=right] .frame { direction: rtl; margin-left: -12px; padding-left: 26px; margin-right: 0; padding-right: 20px; }
	.window[data-scroll=yes] .frame * { direction: ltr; }
	.window[data-scroll=yes] .frame::-webkit-scrollbar { width: 6px; }
	.window[data-scroll=yes] .frame::-webkit-scrollbar-thumb:vertical { background-color: #fff; }
	.window[data-scroll=yes] .frame::-webkit-scrollbar-track-piece { background-color: rgba(0,0,0,0); margin: 0; }
	.window[data-active=no] { filter: brightness(0.7); }
	.window .headerBox .widget.spinner.horizontal { position: absolute; top: 20px; right: 20px; }
	.window .headerBox .widget.header h3 { overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
	.window .footerBox { text-align: right; border-top: 1px solid #e6e6e6; margin: 16px -20px 0; padding: 20px 20px 0 20px; }
	.window .footerBox:empty { padding-top: 0; margin-top: 0; border: none; }
	.window .footerBox .widget.button { margin: 0 0 0 8px; }
	.window .footerBox .widget.dropdown { margin: 0 8px 0 0; }
	.window .footerBox .widget.checkbox { margin: 0 8px 0 0; }
	.window .footerBox .leftBox .widget.button { margin: 0 8px 0 0; }
	.window .footerBox .leftBox .widget.buttonBar { margin: 0 8px 0 0; }
	.window .windowContent { height: 100%; }
	.window .contentWrapper { margin: 0 -20px; padding: 0 20px; }
	.window > [tabindex] { outline: none; }
`);

Window = Class.create();
Window.count = 0;
Window.list = [];
Window.prototype = {
	initialize: function(options) {
		this.options = Object.assign({
			pointer: 		null,
			width: 			400,
			height: 		400,
			left:			null,
			right:			null,
			top:			null,
			title: 			null,
			icon:			null,
			className:		null,
			onClose: 		null,
			onShow: 		null,
			modal:			false,
			animate:		true,
			overlay:		true
		}, options || {});


		this._internal = {
			wrapper: null,
			frame: null,
			pointer: {
				side:		null,
				target:		null,
				distance:	30,
				corner:		30,
				margin:		28,
				size:		28
			},

			state: {
				window:		{ top: 0, left: 0, width: 0, height: 0, side: null },
				target: 	{ top: 0, left: 0, width: 0, height: 0 }
			}
		}

		this.closing = false;

		this.window = new Element('div', { 'class': 'window' });


		if (System.Type.Mobile) {
			this.contentsWidth = null;

			if (this.options.width + 80 > document.body.clientWidth) {
				this.contentsWidth = this.options.width;
				this.options.width = document.body.clientWidth - 40;

				this.window.classList.add('isFullWidth');
			}
		}

		Element.setStyle(this.window, {
			width:	(this.options.width + 40) + 'px',
			height:	(this.options.height + 40) + 'px'
		});

		if (this.options.className) {
			this.window.classList.add(...this.options.className.split(' '));
		}

		if (this.options.pointer) {
			let pointer = {};
			
			/* If the pointer is a string, it is a side */

			if (typeof this.options.pointer == 'string') {
				pointer.side = this.options.pointer;
			}

			/* If the pointer is a DOM element */

			else if(this.options.pointer instanceof HTMLElement) {
				pointer.target = this.options.pointer;
			}

			/* If the pointer is a widget, find the container */

			else if (typeof this.options.pointer == 'object' && typeof this.options.pointer.container != 'undefined') {
				pointer.target = this.options.pointer;
			}

			/* If the pointer is an object, it is a configuration object */

			else if (typeof this.options.pointer == 'object') {
				pointer = this.options.pointer;
			}

			this._internal.pointer = Object.assign(this._internal.pointer, pointer);

			/* Create the tip */

			this.tip = new Element('div', { 'class': 'tip' }).update(' ');
			this.window.appendChild(this.tip);
		}


		this._internal.frame = new Element('div', { 'class': 'frame' });
		this.window.appendChild(this._internal.frame);


		var flex = new Layout.Flex(this._internal.frame, {
			orientation:	'vertical',
			items:			[
								{ name: 'header', height: '36px' },
								{ name: 'content', flexible: true },
								{ name: 'footer', className: 'footer' }
							]
		});

		this.header = flex.items.header;
		this.footer = flex.items.footer;


		if (System.Type.Mobile) {
			this.container = new Element('div');

			this._internal.wrapper = flex.items.content;
			this._internal.wrapper.appendChild(this.container);

			if (this.contentsWidth) {
				this.container.style.width = this.contentsWidth + 'px';
				this._internal.wrapper.style.overflowY = 'auto';
				this._internal.wrapper.classList.add('contentWrapper');
			}
		} else {
			this.container = flex.items.content;
		}

		this.header.classList.add('windowHeader');
		this.container.classList.add('windowContent');
		this.footer.classList.add('windowFooter');

		this.windowTitle = new Widgets.Header(this.header, {
			title:	this.options.title || '',
			icon:	this.options.icon,
			style:	'window',
		});

		if (!this.options.title) {
			this.header.hide();
		}

		this.trapFocus(this._internal.frame);

		this.window.hide();
		document.body.appendChild(this.window);

		/* for backwards compatibility */
		this.contents = this.container;
	},

	focus: function() {
		if (this._internal.focusTrap.frontTarget) {
			this._internal.focusTrap.frontTarget.focus();
		}
	},

	show: function() {
		Window.count++;
		Window.list.push(this);
		Window.render();

		this.window.style.zIndex = ((Window.count + 1) * 1000) + 1;


		/* If an element outside of the window still has focus, blur it */
		this.previousFocus = document.activeElement;

		if (document.activeElement) {
			document.activeElement.blur();
		}


		requestAnimationFrame(() => {

			/* Determine positioning */

			this.positioning = 'center';

			if (this.options.top && (this.options.left || this.options.right)) {
				this.positioning = 'fixed';
			}
			else if (this._internal.pointer.target) {
				this.positioning = 'auto';
			}


			/* Notify others that a window is opening */

			document.fire('window:open', this);


			/* Position window */

			this.positionWindow();

			if (this.options.overlay) {
				this.window.classList.add('hasOverlay');
			}

			if (this.options.animate) {
				Fx.appear(this.window, {

					after: function() {
						this.focus();

						if (this.options.onShow) {
							this.options.onShow();
						}

						this.startInspection();
					}.bind(this)
				})
			} else {
				this.window.show();

				this.focus();
				
				if (this.options.onShow) {
					this.options.onShow();
				}

				this.startInspection();
			}

			if (this.options.overlay) {
				if (Overlay.count == 0) {
					this.window.classList.add('primary');
				}

				this.overlay = new Overlay ({
					onHide: this.options.modal ? null : this.close.bind(this),
					zIndex: ((Window.count + 1) * 1000) - 1,
					animate: this.options.animate,
					owner: this.window
				});
			}
		});
	},

	trapFocus: function(element) {
		this._internal.focusTrap = {};

		this._internal.focusTrap.frontCatch = new Element('div');
		this._internal.focusTrap.frontCatch.tabIndex = 0;
		this._internal.focusTrap.frontCatch.onfocus = () => this._internal.focusTrap.endTarget.focus();
		element.insertAdjacentElement('beforebegin', this._internal.focusTrap.frontCatch)

		this._internal.focusTrap.frontTarget = new Element('div');
		this._internal.focusTrap.frontTarget.tabIndex = -1;
		element.insertAdjacentElement('beforebegin', this._internal.focusTrap.frontTarget)

		this._internal.focusTrap.endCatch = new Element('div');
		this._internal.focusTrap.endCatch.tabIndex = 0;
		this._internal.focusTrap.endCatch.onfocus = () => this._internal.focusTrap.frontTarget.focus();
		element.insertAdjacentElement('afterend', this._internal.focusTrap.endCatch)

		this._internal.focusTrap.endTarget = new Element('div');
		this._internal.focusTrap.endTarget.tabIndex = -1;
		element.insertAdjacentElement('afterend', this._internal.focusTrap.endTarget)
	},

	startInspection: function() {
		window.addEventListener('resize', this.viewPortHasResized.bind(this));
		window.addEventListener('orientationchange', this.viewPortHasRotated.bind(this));

		if (typeof ResizeObserver == 'undefined') {
			this.resizeInterval = window.setInterval(this.manuallyCheckForChanges.bind(this), 100)
			return;
		}

		this.observer = new ResizeObserver(entries => {
			requestAnimationFrame(_ => {
				entries.forEach(entry => {
					if (entry.target == this.window) {
						
						/* Check if the window has been resized */

						if (entry.contentRect.width != this._internal.state.window.width || 
							entry.contentRect.height != this._internal.state.window.height) 
						{
							this.windowSizeHasChanged();
						}
					}

					if (entry.target == this._internal.pointer.target) {

						/* Check if the target has been resized or repositioned */

						let rect = entry.target.getBoundingClientRect();

						if (rect.left != this._internal.state.target.left ||
							rect.top != this._internal.state.target.top ||
							rect.width != this._internal.state.target.width ||
							rect.height != this._internal.state.target.height) 
						{
							this.positionWindowLinkedToElement();
						}
					}
				});
			});
		});

		this.observer.observe(this.window);
		if (this.positioning == 'auto') {
			this.observer.observe(this._internal.pointer.target);
		}
	},

	stopInspection: function() {
		window.removeEventListener('resize', this.viewPortHasResized.bind(this));
		window.removeEventListener('orientationchange', this.viewPortHasRotated.bind(this));
		
		if (this.resizeInterval) {
			window.clearInterval(this.resizeInterval);
		}

		if (this.observer) {
			this.observer.disconnect();
		}
	},

	manuallyCheckForChanges: function() {
		if (!this.previousWindowDimensions) {
			this.previousWindowDimensions = this.window.getBoundingClientRect();
			return;
		}

		var currentWindowDimensions = this.window.getBoundingClientRect();

		if (Math.abs(currentWindowDimensions.width - this.previousWindowDimensions.width) > 2 || 
			Math.abs(currentWindowDimensions.height - this.previousWindowDimensions.height) > 2) 
		{
			this.windowSizeHasChanged();

			this.previousWindowDimensions = currentWindowDimensions;
		}


		if (this.positioning == 'auto') {
			if (!this.previousPointerDimensions) {
				this.previousPointerDimensions = this._internal.pointer.target.getBoundingClientRect();
				return;
			}

			var currentPointerDimensions = this._internal.pointer.target.getBoundingClientRect();

			if (currentPointerDimensions.left != this.previousPointerDimensions.left) {
				this.positionWindowLinkedToElement();

				this.previousPointerDimensions = currentPointerDimensions;
			}
		}
	},

	viewPortHasResized: function() {
		if (System.Type.Mobile) {
			return;
		}

		if (this.positioning == 'center') {
			this.positionWindowCentered();
		}

		if (this.positioning == 'auto') {
			this.positionWindowLinkedToElement();
		}
	},

	viewPortHasRotated: function() {
		if (this.positioning == 'center') {
			setTimeout(this.positionWindowCentered.bind(this), 100);
		}
	},

	windowSizeHasChanged: function() {
		this.positionWindow();
	},


	positionWindow: function() {
		if (this.positioning == 'center') {
			this.positionWindowCentered();
		}

		if (this.positioning == 'fixed') {
			this.positionWindowFixed();
		}

		if (this.positioning == 'auto') {
			this.positionWindowLinkedToElement();
		}
	},

	positionWindowLinkedToElement: function() {
		var pointerDimensions = this._internal.pointer.target.getBoundingClientRect();
		var windowDimensions = this.window.getDimensions();


		// Find side with the most room

		var side = this._internal.pointer.side;

		if (!side) {
			var sides = [
				[ 'top', pointerDimensions.top - windowDimensions.height ],
				[ 'left', pointerDimensions.left - windowDimensions.width ],
				[ 'right', window.innerWidth - pointerDimensions.left - pointerDimensions.width - windowDimensions.width ],
				[ 'bottom',	window.innerHeight - pointerDimensions.top - pointerDimensions.height - windowDimensions.height ]
			]

			let candidates = sides.filter(function(s) { return s[1] > 0 }).map(s => s[0]);
			let optimal = sides[sides.reduce(function(p, c, i) { return c[1] > sides[p][1] ? i : p }, 0)][0]

			if (this._internal.state.window.side) {

				/* When repositioning, we want to keep the current side if it is still a candidate */

				if (candidates.includes(this._internal.state.window.side)) {
					side = this._internal.state.window.side;
				}
				else {
					side = optimal;
				}
			}
			else {
				side = optimal;
			}
		}

		// Based on the side determine the quadrant where we are going to show the dialog

		var quadrant;

		if (side == 'left' || side == 'right') {
			quadrant = pointerDimensions.top + (pointerDimensions.height / 2) < window.innerHeight / 2 ? 'top' : 'bottom';
		}

		if (side == 'top' || side == 'bottom') {
			quadrant = pointerDimensions.left + (pointerDimensions.width / 2) < window.innerWidth / 2 ? 'left' : 'right';
		}


		var left, right, top, bottom;
		var distance = this._internal.pointer.distance;
		var size = this._internal.pointer.size;
		var margin = this._internal.pointer.margin;
		var pointer = true;


		// Determine the position on the axis running through the pointer object

		switch(side) {
			case 'left': left = pointerDimensions.left - windowDimensions.width - distance; break;
			case 'right': left = pointerDimensions.left + pointerDimensions.width + distance; break;
			case 'top': top = pointerDimensions.top - windowDimensions.height - distance; break;
			case 'bottom': top = pointerDimensions.top + pointerDimensions.height + distance; break;
		}

		// Determine the position on the axis crossing the previous axis

		switch(quadrant) {
			case 'top': top = Math.max(margin, (pointerDimensions.top + (pointerDimensions.height / 2)) - (windowDimensions.height / 2)); break;
			case 'bottom': bottom = Math.max(margin, window.innerHeight - ((pointerDimensions.top + (pointerDimensions.height / 2)) + (windowDimensions.height / 2))); break;
			case 'left': left = Math.max(margin, (pointerDimensions.left + (pointerDimensions.width / 2)) - (windowDimensions.width / 2)); break;
			case 'right': right = Math.max(margin, window.innerWidth - ((pointerDimensions.left + (pointerDimensions.width / 2)) + (windowDimensions.width / 2))); break;
		}


		// Always use top and left for positioning

		if (right) {
			left = window.innerWidth - windowDimensions.width - right;
		}

		if (bottom) {
			top = window.innerHeight - windowDimensions.height - bottom;
		}


		// Make sure our window does not go offscreen to the left or right

		if (left < margin) {
			left = margin;
		}

		if (left + windowDimensions.width > window.innerWidth - margin) {
			left = window.innerWidth - windowDimensions.width - margin;
		}


		// Make sure our window does not go offscreen on the top or bottom 

		if (top < 0 || top + windowDimensions.height > window.innerHeight) {
			top = distance;
			this._internal.frame.style.maxHeight = (window.innerHeight - margin - margin - 20 - 20) + 'px';
			this.window.dataset.scroll = 'yes';
		}


		// Check if the tip overshot the target, if so, hide the tip

		if (side == 'top') {
			if (top + windowDimensions.height + (size / 2) > pointerDimensions.top + pointerDimensions.height) {
				pointer = false;
			}
		}

		if (side == 'bottom') {
			if (top - (size / 2) < pointerDimensions.top) {
				pointer = false;
			}
		}

		if (side == 'left') {
			if (left + windowDimensions.width + (size / 2) > pointerDimensions.left + pointerDimensions.width) {
				pointer = false;
			}
		}

		if (side == 'right') {
			if (left - (size / 2) < pointerDimensions.left) {
				pointer = false;
			}
		}


		// Position window 

		this.window.style.transform = 'translate(' + parseInt(left, 10) + 'px, ' + parseInt(top, 10) + 'px)';


		// Set the correct type style 

		this.window.dataset.tip = '';

		if (pointer) {
			switch(side) {
				case 'left': this.window.dataset.tip = 'right'; break;
				case 'right': this.window.dataset.tip = 'left'; break;
				case 'top': this.window.dataset.tip = 'bottom'; break;
				case 'bottom': this.window.dataset.tip = 'top'; break;
			}
			
			// Position tip

			var corner = this._internal.pointer.corner;
			var position;

			if (side == 'left' || side == 'right') {
				position = (pointerDimensions.top + (pointerDimensions.height / 2) - top - (size / 2));
				position = Math.max(corner, Math.min(windowDimensions.height - corner - size, position));

				this.tip.style.transform = 'translateY(' + position + 'px)';
				this.tip.style.top = '0px';
				this.tip.style.left = '';
			}

			if (side == 'top' || side == 'bottom') {
				position = (pointerDimensions.left + (pointerDimensions.width / 2) - left - (size / 2));
				position = Math.max(corner, Math.min(windowDimensions.width - corner - size, position));

				this.tip.style.transform = 'translateX(' + position + 'px)';
				this.tip.style.top = '';
				this.tip.style.left = '0px';
			}

			this.tip.style.borderWidth = (size / 2) + 'px';
			this.tip.style[this.window.dataset.tip] = (1 - size) + 'px';
		}


		/* Store state of current position */

		this._internal.state.window = {
			top: top,
			left: left,
			width: windowDimensions.width,
			height: windowDimensions.height,
			side: side
		}

		this._internal.state.target = {
			top: pointerDimensions.top,
			left: pointerDimensions.left,
			width: pointerDimensions.width,
			height: pointerDimensions.height
		}
	},

	positionWindowCentered: function() {
		var dimensions = this.window.getDimensions();

		var top = Math.max(0, Math.round((window.innerHeight / 2) - (dimensions.height / 2)));
		var left = Math.round((window.innerWidth / 2) - (dimensions.width / 2));


		if (Window.count > 1) {
			let previousWindow = Window.list[Window.list.length - 2];

			if (previousWindow._internal.state.window.top == top || previousWindow._internal.state.window.left == left) {
				top += 20;
				left += 20;
			}
		}

		this.window.style.transform = 'translate(' + left + 'px, ' + top + 'px)';


		/* Store state of current position */

		this._internal.state.window = {
			top: top,
			left: left,
			width: dimensions.width,
			height: dimensions.height,
			side: null
		}

		this._internal.state.target = {
			top: 0,
			left: 0,
			width: 0,
			height: 0
		}
	},

	positionWindowFixed: function() {
		var dimensions = this.window.getDimensions();
		var left = this.options.left;

		if (this.options.right) {
			var left = window.innerWidth - dimensions.width - this.options.right;
		}

		this.window.style.transform = 'translate(' + left + 'px, ' + this.options.top + 'px)';

		let side = null;

		if (this._internal.pointer.side) {
			side = this._internal.pointer.side;

			if (typeof this._internal.pointer.position != 'undefined') {
				var position = this._internal.pointer.position;
				var size = this._internal.pointer.size;

				if (side == 'left' || side == 'right') {
					this.tip.style.transform = 'translateY(' + position + 'px)';
					this.tip.style.top = '0px';
					this.tip.style.left = '';
				}

				if (side == 'top' || side == 'bottom') {
					if (this.options.right) {
						position = dimensions.width - position - size;
					}

					this.tip.style.transform = 'translateX(' + position + 'px)';
					this.tip.style.top = '';
					this.tip.style.left = '0px';
				}

				this.tip.style.borderWidth = (size / 2) + 'px';
				this.tip.style[this.window.dataset.tip] = (1 - size) + 'px';
			}
		}

		this.window.dataset.tip = side || '';

		/* Store state of current position */

		this._internal.state.window = {
			top: top,
			left: left,
			width: dimensions.width,
			height: dimensions.height
		}

		this._internal.state.target = {
			top: 0,
			left: 0,
			width: 0,
			height: 0,
			side: side
		}
	},


	close: function() {
		if (!this.closing) {
			this.closing = true;

			Window.count--;
			Window.list.pop();
			Window.render();

			if (this.options.animate) {
				Fx.fade(this.window, {
					after: 	function() {
								if (this.options.onClose) {
									this.options.onClose();
								}

								if (this.window) {
									this.window.remove();
								}

								if (this.previousFocus) {
									this.previousFocus.focus();
								}
							}.bind(this)
				});
			} else {
				this.window.hide();

				if (this.options.onClose) {
					this.options.onClose();
				}

				if (this.window) {
					this.window.remove();
				}

				if (this.previousFocus) {
					this.previousFocus.focus();
				}
			}


			if (this.overlay) {
				this.overlay.destroy();
			}
		}

		this.stopInspection();
	},

	appendChild: function(element) {
		this.contents.appendChild(element)
	},

	clear: function() {
		this.contents.innerHTML = '';
	},

	shake: function() {
		new Effect.Shake(this.window);
	},

	isOnTop: function() {
		return Window.list[Window.list.length - 1] == this;
	},

	get title() { return this.options.title; },
	set title(value) { this.options.title = this.windowTitle.value = value; value ? this.header.show() : this.header.hide() },
}

Window.render = function() {
	Window.list.forEach((w, i) => {
		w.window.dataset.stack = i;
		w.window.dataset.active = Window.list.length - 1 == i ? 'yes' : 'no';
	});
}