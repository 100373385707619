Widgets.Header = Class.create(Widgets.Base, {
    name:		'header',

    defaults:	{
        title:		'',
        subtitle:	''
    },

    css: `
        .widget.header { margin-bottom: 1rem; }
        .widget.header p { font-size: 0.75em; color: #888; margin-top: 4px; }
        .widget.header h3 { text-transform: var(--font-transform); font-size: 1.2rem; color: #3E4A59; font-weight: var(--font-weight-bold); }
        .widget.header h3 em { font-style: normal; color: #aaa; }
        .widget.header h3 small { font-size: 60%; color:#aaa; font-style: italic; font-weight: normal; margin-left: 10px; }
        .widget.header h3[data-icon]::before { font-size: 125%; position: relative; top: 1px; margin-right: 3px; }
        .widget.header h3[data-icon="custom-image"] { margin-top: -2px; padding-top: 2px; }
        .widget.header h3[data-icon="custom-image"] img { vertical-align: middle; margin-right: 4px; position: relative; top: -2px; }
        
        /* Styles */

        .widget.header[data-style="section"] {
            display: grid;
            grid-template-columns: min-content auto;
        }

        .widget.header[data-style="section"] > img { grid-column: 1; grid-row: 1 / 3; width: 40px; height: 40px; margin-right: 10px; }
        .widget.header[data-style="section"] > h3 { grid-column: 2; grid-row: 1; }
        .widget.header[data-style="section"] > p { grid-column: 2; grid-row: 2; }

        .widget.header[data-style="window"] {
            display: grid;
            grid-template-columns: min-content auto;
            grid-template-rows: 1rem 1fr 1rem;
            align-items: center;

            margin-top: -1rem;
            margin-bottom: 0;
        }
        .widget.header[data-style="window"] > img { grid-column: 1; grid-row: 1 / 4; width: 32px; height: 32px; margin-right: 4px; position: relative; left: -3px; }
        .widget.header[data-style="window"] > h3 { grid-column: 2; grid-row: 2; }


        /* Sizes */

        .widget.header[data-size="small"] h3 { font-size: 1.0rem; }
        .widget.header[data-size="smaller"] h3 { font-size: 0.9rem; color: #aaa; }
        .widget.header[data-size="smallest"] h3 { font-size: 0.8rem; margin-top: -2.5em; }
        .widget.header[data-size="information"] h3 { font-size: 0.8rem; text-align: center; color: #aaa; padding: 50px 100px 0 100px; }
        .widget.header[data-size="placeholder"] { padding-bottom: 100px; }
        .widget.header[data-size="placeholder"] h3 { font-size: 0.9rem; text-align: center; color: #aaa; padding-top: 100px; }
        .widget.header[data-size="placeholder"] h3[data-icon]::before { font-size: 10rem; display: block; color: #eee; margin-bottom: 20px; text-shadow: 0px -1px 0px #ddd; }
        .widget.header[data-size="placeholder"] p { padding-top: 20px; font-size: 0.7rem; text-align: center; }
    `,

    initWidget: function() {
        if (this.options.style) this.dataset.style = this.options.style;
        if (this.options.size) this.dataset.size = this.options.size;
        
        this.header = new Element('h3');
        this.header.innerHTML = this.options.title;
        this.insert(this.header);

        if (this.options.subtitle != '') {
            this.paragraph = new Element('p');
            this.paragraph.innerHTML = this.options.subtitle;
            this.insert(this.paragraph);
        }

        if (this.options.icon) {
            if (typeof this.options.icon == 'object') 
            {
                if (this.options.icon.id) {
                    this.header.dataset.icon = this.options.icon.id;
                }

                if (this.options.icon.character) {
                    this.header.dataset.icon = 'custom-character';
                    this.header.dataset.iconCharacter = this.options.icon.character;
                }

                if (this.options.icon.color) {
                    this.header.dataset.iconColor = this.options.icon.color;
                }

                if (this.options.icon.src) {
                    this.dataset.icon = 'custom-image';

                    let icon = new Image();
                    icon.width = this.options.icon.width || this.options.icon.size || '28';
                    icon.height = this.options.icon.height || this.options.icon.size || '28';
                    icon.alt = '';

                    let found = this.options.icon.src.match(/([a-z]+)#(.+)/iu);
                    if (found) {
                        icon.src = `${_ROOT}../assets/icons/${found[1]}/icons/${found[2]}.svg`;
                    } else {
                        icon.src = this.options.icon.src;    
                    }

                    this.insertBefore(icon, this.header);
                }
            } else {
                this.header.dataset.icon = this.options.icon;
            }
        }


        if (this.options.size) this.classList.add(this.options.size + 'Size');

        this.defineProperty('value', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.header.innerHTML = value; }
        });

        this.defineProperty('title', {
            get: function() { return this.options.title; },
            set: function(value) { this.options.title = value; this.header.innerHTML = value; }
        });

        this.defineProperty('subtitle', {
            get: function() { return this.options.subtitle; },
            set: function(value) { this.options.subtitle = value; this.paragraph.innerHTML = value; }
        });
    }
});
